import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/SEO';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import Tabs from '../components/Tabs';
import * as style from '../assets/style/pages/offer-page.module.scss';

 const OfferPage = ({ location, data }) => {
  const activeTab = location.state && location.state.tab ? location.state.tab : 'tab-0';
  const crumbs = [
    { title: 'Strona główna', url: '/' },
    { title: 'Oferta', url: '/oferta' }
  ];
  const products = data.offers.edges.map(({ node }) => ({
    title: node.title.value,
    text: node.description.value
  }));

  return (
    <Layout>
      <Seo title="Oferta">
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":
            [${
              crumbs.map(({ title, url }, i) => `
                {
                  "@type": "ListItem",
                  "position": ${i + 1},
                  "item": {
                    "@type": "WebPage",
                    "@id": "${location.origin + url}",
                    "name": "${title}"
                  }
                }
              `)}
            ]
          }`}
        </script>
      </Seo>
      <Breadcrumbs crumbs={crumbs} />
      <section className={style.offer}>
        <div className="container">
          <Tabs title="Oferta" content={products} productsBtn realizationsBtn activeTab={activeTab}/>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    offers: allCockpitOffer {
      edges {
        node {
          id
          title {
            value
          }
          description {
            value
          }
        }
      }
    }
  }
`;

export default OfferPage;
